import React, { useEffect } from "react";
import {Route, Switch} from "react-router-dom";
import config from "../util/config";
import asyncComponent from "util/asyncComponent";

const RedirectToGoogle = () => {
  useEffect(() => {
    window.location.href = config.SmarterDealerUrl;
  }, []);

  return null;
};

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>
      <Route path={`${match.url}dealerships`} component={asyncComponent(() => import('./Client'))}/>
      <Route path={`${match.url}admins`} component={asyncComponent(() => import('./Settings'))}/>
      <Route path={`${match.url}DMS`} component={RedirectToGoogle}/>
      <Route path={`${match.url}calls`} component={asyncComponent(() => import('./Calls'))}/>
    </Switch>
  </div>
);

export default App;
