import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../../constants/ActionTypes";
import axios from 'util/Api'

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({email, password, name}) => {
  console.log(email, password);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/register', {
        email: email,
        password: password,
        name: name
      }
    ).then(({data}) => {
      console.log("data:", data);
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        axios.defaults.headers.common['access-token'] = "Bearer " + data.token.access_token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        console.log("payload: data.error", data.error);
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const userSignIn = ({email, password, token = null}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/login', {
        email: email,
        password: password,
        token: token,
      }
    ).then(({data}) => {
      if (data.status) {
        localStorage.setItem("token", JSON.stringify(data.access_token));
        localStorage.setItem("user", JSON.stringify(data));
        // axios.defaults.headers.common['access-token'] = "Bearer " + data.token.access_token;
        dispatch({type: USER_TOKEN_SET, payload: data.access_token});
        dispatch({type: USER_DATA, payload: data});
        dispatch({type: FETCH_SUCCESS});
        window.location.reload()
      } else {
        dispatch({type: FETCH_ERROR, payload: data.msg});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
export const userSignOut = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    setTimeout(() => {
      dispatch({type: FETCH_SUCCESS});
      dispatch({type: SIGNOUT_USER_SUCCESS});
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.reload()
    }, 2000);
  }
};
